<template>
<div id="testimonials">
    <div class="container">
        <h2 class="sub-headings centered">Feedbacks</h2>

        <div class="test-slide-show flex justify-btw align-center test-slide-show-loader" v-if=" 0 === feedbacks.length">
            <div class="icon-sprite prev-icon"></div>
            <div class="test-slider flex">
                <div class="test-box test-loader-box test-box:first-child">
                    <p class="para-loader"> </p>
                    <p class="para-loader"> </p>
                    <p class="para-loader half"> </p>
                    <span class="span-loader"> </span>
                </div>
                <div class="test-box test-loader-box test-2">
                    <p class="para-loader"> </p>
                    <p class="para-loader"> </p>
                    <p class="para-loader half"> </p>
                    <span class="span-loader"> </span>
                </div>
            </div>
            <div class="icon-sprite next-icon"></div>
        </div>

        <div class="test-slide-show flex justify-btw align-center show-1"
            v-touch:swipe.left="swipeHandler"
            v-touch:swipe.right="swipeHandler"
            v-if="0 < feedbacks.length"
        >
            <div class="icon-sprite prev-icon" @click="handleClick" id="prev-test-btn"></div>
            <div class="test-slider flex">

                <div v-for="(feedback, index) in feedbacks" :key="index" class="test-box" >
                    <p> {{ feedback.message }} </p>
                    <span> - {{ feedback.name }} </span>
                </div>
            </div>
            <div class="icon-sprite next-icon" @click="handleClick" id="next-test-btn"></div>
        </div>

        <div class="test-dots-box flex" v-if="0 < feedbacks.length">

            <div
                v-for="(feedback, index) in feedbacks"
                :key="index"
                class="test-dot"
                :id = " 'test-dot-' + (index + 1) "
                :class=" {
                    'active' : index === 0,
                    'hidden' : index === length - 1 && 930 < viewport
                    } "
                @click="handleDotClick"
            >
            </div>
        </div>
        <button class="btn fancy-btn add-fb-btn" @click="openAddFeedbackOverlay">Add Feedback</button>
    </div>

    <div class="overlay d-none" @click="closeOverlay">
        <div class="modal add-fb-modal d-none" @click="handleModalClick">
            <span @click="closeOverlay" class="close-modal-icon"></span>
            <h2 class="sub-headings centered">Add Feedback</h2>

            <form class="flex">

                <div class="form-element name-element">
                    <input
                        type="text" data-name="name" data-len="3"
                        id="feedbackCustomerName" class="form-input"
                        placeholder="Your Name*" v-model="name"
                        @input="handleInput" @blur="validateFields">
                </div>

                <div class="form-element msg-element">
                    <textarea
                        data-name="Message" data-len="10"
                        id="feedabackMessage" class="form-input message"
                        placeholder="Feedback Message*" v-model="message"
                        @input="handleInput" @blur="validateFields">
                    </textarea>
                </div>

                <div class="btns-box flex">
                    <button class="btn fancy-btn confirm-btn fb-cnfrm-add-btn" type="button" @click="addFeedback">ADD</button>
                    <button class="btn fancy-btn cancel-btn" @click="closeOverlay" type="button">CANCEL</button>
                </div>

            </form>
        </div>
    </div>

</div>
</template>

<script>
import $ from 'jquery';
import { Feedbacks } from '../../services/Service';

export default {
    data() {
        return {
            feedbacks: [],
            currentSlide: 1,
            name: '',
            message: ''
        }
    },
    methods: {
        openAddFeedbackOverlay() {
            $('.overlay').removeClass('d-none');
            $('.add-fb-modal').removeClass('d-none');
            $('.add-fb-modal .error-msg').remove();
        },
        handleModalClick(e) {
            e.stopPropagation();
        },
        closeOverlay() {
            $('.overlay').addClass('d-none');
            $('.modal').addClass('d-none');
            $('.add-fb-modal .error-msg').remove();
        },
        handleInput(e)
        {
            if( e.target.value === '' )
                this.addErrorMessage( e.target.id );
            else
                this.removeErrorMessage( e.target.id );
        },
        validateFields({target: el})
        {
            const { name, len } = el.dataset;
            if(el.value === '')
                this.addErrorMessage( el.id );
            else if( el.value.length < len )
                this.addErrorMessage( el.id, `${name} must have atleast ${len} char.`);
        },
        addErrorMessage( id, msg )
        {
            $(`.${id}-error-msg`).remove();
            var contents;
            if(msg)
                contents = `<p class="error-msg ${id}-error-msg">${msg}</p>`;
            else
                contents = `<p class="error-msg ${id}-error-msg">${$(`#${id}`).data('name')} can't be empty </p>`;
            $(`#${id}`).parent().addClass('error-parent').append(contents);
        },
        removeErrorMessage(id)
        {
            $(`.${id}-error-msg`).remove();
            $(`#${id}`).parent().removeClass('error-parent');
        },
        handleClick(e)
        {
            // When endpoints reached, return.
            if( e.target.id === 'prev-test-btn' )
            {
                if( this.currentSlide === 1 )  { return; }
            }
            else
            {
                if( this.currentSlide === this.feedbacks.length ) { return; }
                else if( 930 < window.innerWidth && this.currentSlide === this.feedbacks.length - 1 ) { return; }
            }

            this.currentSlide = e.target.id === 'prev-test-btn' ? this.currentSlide - 1 : this.currentSlide + 1;

            this.setActiveSlide();
        },
        swipeHandler(dir)
        {
            // When endpoints reached, return.
            if( dir === 'right' )
            {
                if( this.currentSlide === 1)  { return; }
            }
            else
            {
                if( this.currentSlide === this.feedbacks.length ) { return; }
                else if( 930 < window.innerWidth && this.currentSlide === this.feedbacks.length - 1 ) { return; }
            }

            this.currentSlide = dir === 'right' ? this.currentSlide - 1 : this.currentSlide + 1;

            $('.test-dot.active').removeClass('active');
            $('#test-dot-' + this.currentSlide ).addClass('active');
            $('.test-slide-show').attr('class',`test-slide-show flex justify-btw align-center show-${this.currentSlide}`);

            this.setActiveSlide();
        },
        handleDotClick(e)
        {
            var target = Number(e.target.id[e.target.id.length - 1]);
            if( target === 0 ) { target = 10; }
            this.currentSlide = target;
            this.setActiveSlide();
        },
        setActiveSlide()
        {
            if( this.currentSlide === this.feedbacks.length )
            {
                $('.next-icon').addClass('hidden');
            }
            else if( 930 < window.innerWidth && this.currentSlide === this.feedbacks.length - 1  )
            {
                $('.next-icon').addClass('hidden');
            }
            else
            {
                $('.next-icon').removeClass('hidden');
            }

            $('.test-dot.active').removeClass('active');
            $('#test-dot-' + this.currentSlide ).addClass('active');
            $('.test-slide-show').attr('class',`test-slide-show flex justify-btw align-center show-${this.currentSlide}`);
        },
        async addFeedback() {
            if( this.name === '' )
                this.addErrorMessage('feedbackCustomerName');
            else if( this.name.length < 3 )
                this.addErrorMessage('feedbackCustomerName', 'Name must have atleast 3 chars.' );

            if( this.message === '' )
                this.addErrorMessage('feedabackMessage');
            else if( this.message.length < 10 )
                this.addErrorMessage('feedabackMessage','Message must have atleast 10 chars.');

            if( $('.add-fb-modal .error-msg').length ) return;
            $('.fb-cnfrm-add-btn').blur();
            $('.fb-cnfrm-add-btn').html(`<div class="spinner-border" role="status"></div>`);
            try {
                const response = await Feedbacks.add( { name: this.name, message: this.message });
                if( response.status === 200 )
                {
                    $('.fb-cnfrm-add-btn').html(`Add`);
                    const notification = {
                        type: 'success',
                        message: 'Feedback added successfully.'
                    };
                    this.$store.dispatch('addNotification', notification);
                    this.name = '';
                    this.message = '';

                    this.closeOverlay();
                }
            } catch (error)
            {
                const { message } = error.response.data;
                const notification = { type: 'error', message }
                $('.fb-cnfrm-add-btn').html(`Add`);

                this.$store.dispatch('addNotification', notification);
            }
        },
        async fetchAllDisplayedFeedbacks() {
            const response = await Feedbacks.getDisplayed();
            if( response.status === 200 )
            {
                var { result } = response.data;
                result = 10 < result.length ? result.slice(0, 10) : result;
                this.feedbacks = result;
            }
        }
    },
    created () {
        this.fetchAllDisplayedFeedbacks();
    },
    computed: {
        length() {
            return this.feedbacks.length
        },
        viewport() {
            return window.innerWidth;
        }
    },
}
</script>

<style scoped>

/* Loaders */

.test-slide-show-loader .icon-sprite { visibility: hidden; }

.test-loader-box { min-height: 200px; }

.para-loader, .span-loader
{
    display: block;
    height: 20px; width: 100%;
    background-color: rgb(255, 255, 255,0.25);
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    margin: 15px 0;
}

.para-loader.half { width: 50%; }

.span-loader
{
    width: 25%;
    margin-left: auto;
    color: #E20613;
}

.pseudo-loader::after, .span-loader::after
{
    content: '';
    position: absolute;
    height: 100%; width: 30%;
    background-color: rgb(0, 0, 0,0.25);
    top: 0; left: 0;
    animation: load 0.4s linear infinite;
    transform: skewX(30deg);
}

.span-loader::after { background-color: #E20613; opacity: 0.25; }


#testimonials { padding: 100px 0; }

.icon-sprite
{
    background-size: 702px 490.2px;
    background-position: -637.3px -53.4px;
	height: 50px; width: 50px;
    cursor: pointer;
    filter: drop-shadow(0px 0px 2px rgb(0,0,0,0.1));
    transition: transform 0.2s ease;
}

.prev-icon:hover { transform: scale3d(1.1,1.1,1.1); }

.next-icon:hover { transform: rotate(180deg) scale3d(1.1,1.1,1.1); }

.next-icon { transform: rotate(180deg); }

/* Hide Prev icon when showing 1 */
.test-slide-show.show-1 .prev-icon { visibility: hidden; }

.test-slide-show { width: 100%; }

.test-slider
{
    width: calc(100% - 150px);
    flex-wrap: nowrap;
    overflow-x: hidden;
}

.test-box
{
    min-width: calc(50% - 50px);
    min-height: 200px;
    padding: 40px 40px 25px;
    background-color: rgb(0,0,0,0.85);
    border-radius: 6px;
    box-shadow: 6px 6px 10px rgb(0,0,0,0.15);
    margin: 0 25px;
    transition: margin 0.5s linear;
}

.test-slide-show.show-1 .test-box:first-child { margin-left: calc(0% + 25px); }

.test-slide-show.show-2 .test-box:first-child { margin-left: calc(-50% + 25px); }

.test-slide-show.show-3 .test-box:first-child { margin-left: calc(-100% + 25px); }

.test-slide-show.show-4 .test-box:first-child { margin-left: calc(-150% + 25px); }

.test-slide-show.show-5 .test-box:first-child { margin-left: calc(-200% + 25px); }

.test-slide-show.show-6 .test-box:first-child { margin-left: calc(-250% + 25px); }

.test-slide-show.show-7 .test-box:first-child { margin-left: calc(-300% + 25px); }

.test-slide-show.show-8 .test-box:first-child { margin-left: calc(-350% + 25px); }

.test-slide-show.show-9 .test-box:first-child { margin-left: calc(-400% + 25px); }

.test-slide-show.show-10 .test-box:first-child { margin-left: calc(-450% + 25px); }

p
{
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
    line-height: 1.75;
    color: #fff;
}

span
{
    font-weight: 800;
    font-size: 18px;
    line-height: 1.75;
    color: #E20613;
    display: block;
    margin: 12px 0 0 auto;
    text-align: right;
}

.test-dots-box
{
    margin-top: 50px;
    justify-content: center;
}

.test-dot
{
    height: 12px; width: 12px;
    background-color: rgb(0,0,0,0.8);
    border-radius: 50%;
    margin: 0 4px;
    cursor: pointer;
}

.test-dot.active { background-color: #E20613; }

.test-dot.hidden, .next-icon.hidden { visibility: hidden; }

/* Add */

.add-fb-btn
{
    margin: 35px auto 0;
    width: 200px; height: 50px;
    display: block;
    padding: 0 30px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 17px;
    color: #ffffff;
    box-shadow: 2px 2px 4px rgb(0, 0, 0,0.2);
    background-color: #000;
}

.add-fb-btn::after { background-color: #E20613; }

/* Overlay */

.overlay
{
    background-color: rgb(0,0,0,0.5);
    height: 100vh; width: 100vw;
    position: fixed;
    top: 0; left: 0;
    z-index: 10;
}

.modal
{
    position: fixed;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    width: 100%;
    max-width: 400px;
    border-radius: 12px;
    padding: 60px 30px 30px;
}

.add-order-modal
{
    padding: 40px 30px 20px;
    width: 100%;
    max-width: 700px;
    overflow-y: auto;
    height: calc(100vh - 100px);
    background-image: url('../../assets/Goemetirc Textures.png');
}

.close-modal-icon
{
    position: fixed;
    right: 15px; top: 5px;
    cursor: pointer;
    width: 30px; height: 30px;
}

.close-modal-icon::before, .close-modal-icon::after
{
    content: '';
    position: absolute;
    width: 3px; height: 30px;
    top: 0; left: 50%;
    background-color: black;
}

.close-modal-icon::before { transform: translateX(-50%) rotate(45deg); }

.close-modal-icon::after { transform: translateX(-50%) rotate(-45deg); }

.close-modal-icon:hover { opacity: 0.5; }

.modal p
{
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
    text-align: center;
}

.confirm-btn, .cancel-btn
{
    height: 45px; width: calc(50% - 8px);
    border-radius: 3px;
    font-size: 16px;
    padding-top: 3px;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.3);
    color: #fff;
    font-weight: 700;
}

.confirm-btn { background-color: #00917c; margin-right: 16px; }

.cancel-btn { background-color: #E20613;  }

.modal h2
{
    margin-bottom: 50px;
    text-align: center;
    font-size: 30px;
}

form { flex-direction: column; }

form>* { margin-bottom: 24px; }

.form-row
{
    justify-content: space-between;
}

.form-input
{
    height: 45px; width: 100%;
    border: 3px solid #000;
    background-color: white;
    box-shadow: 3px 3px 4px rgb(255, 255, 255,0.15);
    outline: 0;
    padding-left: 18px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    border-radius: 3px;
}

.form-input:focus { border-color: #00917c; }

.form-element
{
    position: relative;
    width: 100%;
}

.message { height: 120px; padding: 15px; resize: none; line-height: 1.5; }

@media (max-width: 1230px)
{
    .container { width: calc(100vw - 30px); }

    .test-slide-show { width: 100%; }
}

@media (max-width: 930px)
{
    .test-slider { width: calc(100% - 100px); padding: 0 15px; }

    .test-box
    {
        width: 100%;
        min-width: 100%;
        margin: 0 15px;
    }

    .test-slide-show.show-1 .test-box:first-child { margin-left: 0; }

    .test-slide-show.show-2 .test-box:first-child { margin-left: calc(-100% - 30px); }

    .test-slide-show.show-3 .test-box:first-child { margin-left: calc(-200% - 60px); }

    .test-slide-show.show-4 .test-box:first-child { margin-left: calc(-300% - 90px); }

    .test-slide-show.show-5 .test-box:first-child { margin-left: calc(-400% - 120px); }

    .test-slide-show.show-6 .test-box:first-child { margin-left: calc(-500% - 150px); }

    .test-slide-show.show-7 .test-box:first-child { margin-left: calc(-600% - 180px); }

    .test-slide-show.show-8 .test-box:first-child { margin-left: calc(-700% - 210px); }

    .test-slide-show.show-9 .test-box:first-child { margin-left: calc(-800% - 240px); }

    .test-slide-show.show-10 .test-box:first-child { margin-left: calc(-900% - 270px); }
}

@media (max-width: 600px)
{
    .icon-sprite { display: none; }

    .container { padding: 0; width: calc(100vw - 30px); }

    .test-slide-show { margin: 0; width: 100%;  }

    .test-slider { margin: 0; width: 100%; padding: 0; }

    .test-box
    {
        padding: 50px 20px 30px;
        width: 100%;
        min-width: 100%;
        margin: 0 10px 0 0;
    }

    .test-slide-show.show-2 .test-box:first-child { margin-left: calc(-100% - 10px); }

    .test-slide-show.show-3 .test-box:first-child { margin-left: calc(-200% - 20px); }

    .test-slide-show.show-4 .test-box:first-child { margin-left: calc(-300% - 30px); }

    .test-slide-show.show-5 .test-box:first-child { margin-left: calc(-400% - 40px); }

    .test-slide-show.show-6 .test-box:first-child { margin-left: calc(-500% - 50px); }

    .test-slide-show.show-7 .test-box:first-child { margin-left: calc(-600% - 60px); }

    .test-slide-show.show-8 .test-box:first-child { margin-left: calc(-700% - 70px); }

    .test-slide-show.show-9 .test-box:first-child { margin-left: calc(-800% - 80px); }

    .test-slide-show.show-10 .test-box:first-child { margin-left: calc(-900% - 90px); }

    .add-fb-btn { width: 100%; }
}

</style>