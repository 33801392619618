import axios from "axios";

export var config = {
    baseURL: "https://mobilesolutions-server.herokuapp.com/",
    // baseURL: "http://localhost:3000/",
    headers: {
        'Accept': "application/json",
        'Content-Type': 'application/json'
    }
};

var apiClient = axios.create(config);
export const UpdateApiClient = () => { apiClient = axios.create(config); }

export const Admin = {
    signup: credentials => apiClient.post('/signup', credentials),
    login: credentials => apiClient.post('/login', credentials),
    checkPassword: credentials => apiClient.post('/password/check', credentials ),
    changePassword: credentials => apiClient.patch('/password/change', credentials )
};

export const Appointments = {
    add: credentials => apiClient.post('/appointments', credentials),
    get: credentials => apiClient.get('/appointments', { params: credentials } ),
    getAll: () => apiClient.get('/appointments/all' ),
    delete: credentials => apiClient.delete('/appointments', { params: credentials } ),
    deleteOld: () => apiClient.delete('/appointments/old')
};

export const Orders = {
    add: credentials => apiClient.post('/orders', credentials),
    get: credentials => apiClient.get('/orders', { params: credentials } ),
    getByID: credentials => apiClient.get('/orders/by-id', { params: credentials } ),
    edit: credentials => apiClient.patch('/orders/edit', credentials),
    ready: credentials => apiClient.patch('/orders/ready', credentials),
    deliver: credentials => apiClient.patch('/orders/deliver', credentials),
    delete: credentials => apiClient.delete('/orders', { params: credentials } ),
    deleteOld: () => apiClient.delete('/orders/old')
};

export const Feedbacks = {
    add: credentials => apiClient.post('/feedbacks', credentials),
    getAll: () => apiClient.get('/feedbacks'),
    getDisplayed: () => apiClient.get('/feedbacks/displayed'),
    update: credentials => apiClient.patch('/feedbacks', credentials),
    getByID: credentials => apiClient.get('/feedbacks/by-id', { params: credentials } ),
    delete: credentials => apiClient.delete('/feedbacks', { params: credentials } )
};

export const Quotes = {
    add: credentials => apiClient.post('/quotes', credentials),
    get: () => apiClient.get('/quotes'),
    deleteOld: () => apiClient.delete('/quotes/old')
};

export const SMS = {
    getBalance: () => apiClient.get('/sms/get-balance')
};