<template>
<div id="locate-comp">
    <div class="container">
        <h2 class="sub-headings">Locate Us</h2>

        <iframe
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=mobile%20solutions%20Kallakurichi&t=&z=19&ie=UTF8&iwloc=&output=embed"
            frameborder="0" scrolling="no" marginheight="0" marginwidth="0" allowfullscreen
        >
        </iframe>

    </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
#locate-comp
{
    padding: 100px 0;
}

#gmap_canvas
{
    margin-top: 30px;
    overflow: hidden;
    background: rgb(0, 0, 0,0.8)!important;
    border-radius: 6px;
    width: 100%; height: 400px;
    box-shadow: 0px 0px 6px 2px rgb(0, 0, 0,0.2);
}

</style>