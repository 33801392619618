<template>
<div class="notification-container">
    <NotificationBar
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
    />
</div>
</template>

<script>
import NotificationBar from '@/components/Notification/NotificationBar.vue';

export default {
    components: {
        NotificationBar,
    },
    computed: {
        notifications() {
            return this.$store.state.notifications;
        }
    },
}
</script>

<style scoped>

.notification-container
{
    position: fixed;
    top: 0; right: 25px;
    z-index: 10;
    pointer-events: none;
}

@media (max-width: 425px)
{
    .notification-container
    {
        width: 90%;
        right: 50%;
        transform: translateX(50%);
    }
}

</style>