<template>
<div id="banner">
    <div class="container flex">

        <h1>We bring your <span>mobile</span> back to life</h1>
        <p>
            <span class="side-heading span-1"> <b>Smartphone</b> broken ?</span>
            <span class="side-heading span-2 d-none"> <b>Camera</b> problem ?</span>
            <span class="side-heading span-3 d-none"> <b>Screen</b> not working ?</span>
            <span class="side-heading span-4 d-none"> <b>Charging</b> problem ?</span>
            <span class="side-heading span-5 d-none"> <b>Speaker</b> not working ?</span>
            We can help.
        </p>
        <div class="btn-box flex">
            <router-link :to="{name: 'getquote'}" class="flex center btn btn1">
                Get Quote
            </router-link>
            <a href="tel:919159182128" class="btn btn2 flex center">
                Make a Call
            </a>
        </div>

        <img
            src="../../assets/Mobile Solutions Banner Image.png" alt="Mobile Solutions Banner Image" class="banner-img">

    </div>
</div>
</template>

<script>
import $ from 'jquery'

var currentSpan = 1;
setInterval( () => {
    $('.side-heading').addClass('d-none');
    currentSpan++;
    if(currentSpan === 6) currentSpan = 1;
    $('.span-'+currentSpan).removeClass('d-none');
}, 2500);

export default {}
</script>

<style scoped>

#banner
{
    background: url('../../assets/Background Images/banner_background.jpg');
    background-size: cover;
    position: relative;
    z-index: 1;
}

#banner * { font-family: 'Poppins', sans-serif; }

#banner::before
{
    content: '';
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    background-color: rgb(0, 0, 0,0.65);
    z-index: -1;
}

.container
{
    height: calc(100vh - 100px);
    min-height: 650px;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

h1, h1 span
{
    color: #ffffff;
    font-weight: 900;
    font-size: 60px;
    width: 720px;
    font-style: italic;
}

p { margin: 24px 0 20px; }

p, p>*
{
    color: #ffffff;
    font-weight: 600;
    font-size: 22px;
}

h1 span, p b { color: #E20613; }

.btn
{
    height: 50px;
    padding: 0 30px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 17px;
    color: #ffffff;
    box-shadow: 0px 0px 6px rgb(255, 255, 255,0.2);
    transition: all 0.1s ease;
}

.btn1
{
    border: 2px solid transparent;
    background-color: #E20613;
}

.btn1:hover
{
    background-color: rgb(0, 0, 0,0.25);
    border: 2px solid #E20613;
}

.btn2
{
    margin-left: 24px;
    border: 2px solid #E20613;
    background-color: rgb(0, 0, 0,0.25);
}

.btn2:hover
{
    background-color: #E20613;
    border: 2px solid #E20613;
}

.banner-img
{
    height: 100%;
    max-height: 650px;
    position: absolute;
    right: 0; top: 50%; transform: translateY(-50%);
}

@media (max-width: 1200px)
{
    .banner-img { height: 500px; top: 50%; transform: translateY(-50%); }
}

@media (max-width: 1023px)
{
    .container { height: auto; padding: 75px 15px 100px; text-align: center; min-height: auto; }

    .banner-img { display: none; }

    h1 { margin: 0 auto; }

    .btn-box { justify-content: center; }
}

@media (max-width: 767px)
{
    h1, h1 span {  width: 550px; font-size: 45px; }
}

@media (max-width: 580px)
{
    h1, h1 span {  width: 100%; font-size: 45px; }
}

@media (max-width: 475px)
{
    #banner { background-position-x: -170px; }

    h1, h1 span { font-size: 30px; }

    p span { display: block; }

    .btn { width: 90%; padding: 0; margin: 0 auto 24px; }

    .btn:last-child { margin-bottom: 0; }
}

@media (max-width: 425px)
{
    #banner { background-position-x: -200px; }
}

@media (max-width: 375px)
{
    #banner { background-position-x: -265px; }
}

</style>