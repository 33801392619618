<template>
<div id="book-app">
    <div class="container">
        <h2 class="sub-headings">
            Book An <span>Appointment</span>
        </h2>
        <p>
            We work from 9 AM to 9 PM on Mondays to Saturdays and we are closed on Sundays.
            <br />
            Schedule an appointment and come visit us.
        </p>

        <form class="flex">
            <div class="form-row flex">
                <div class="date-outer form-element">
                    <input id="proxy-date" style="display: none;" data-name="date" type="hidden" />
                     <Datepicker
                        class="date-picker" placeholder="Choose a date"
                        v-model="appDate" wrapper-class="app-dp-wrapper"
                        input-class="app-dp-input" calendar-class="app-dp-cal"
                        :monday-first="true" :disabled-dates="disabledDates"
                    >
                    </Datepicker>
                    <div class="icon-sprite date"></div>
                </div>
                <div class="time-outer form-element d-none">
                    <select id="app-time" class="form-input" v-model="appTime" data-name="time">
                        <option v-for="(t,i) in timeStramp" :key="i" :value="t.time"
                            :disabled="t.disabled === true">
                                {{ t.time }}
                        </option>
                    </select>
                    <label for="app-time" class="app-time-label">Choose a time</label>
                    <div class="icon-sprite dropdown"></div>
                </div>
            </div>
            <div class="form-row flex customer-row-1 d-none">
                <div class="form-element">
                    <input
                        type="text" data-name="customer name" data-len="3"
                        id="c-name" class="form-input"
                        placeholder="Your name" v-model="cName"
                        @input="handleInput" @blur="validateFields">
                </div>
                <div class="form-element c-email-outer">
                    <input
                        type="email" data-name="customer email"
                        id="c-email" class="form-input c-email"
                        placeholder="Your Email" v-model="cEmail"
                        @input="handleInput" @blur="validateEmail">
                </div>

            </div>
            <div class="form-row flex customer-row-2 d-none">
                <div class="form-element">
                    <input
                        type="number" data-name="Mobile Number" data-len="10"
                        id="c-mobile-number" class="form-input"
                        placeholder="Mobile Number" v-model="cMobileNumber"
                        @input="handleInput" @blur="validateFields">
                </div>
                <div class="form-element app-type-outer">
                    <select id="app-type" class="form-input app-type" v-model="appType" data-name="type">
                        <option value="" selected disabled>Choose a Type</option>
                        <option value="visitshop">Visit Shop</option>
                        <option value="offline">Offline</option>
                    </select>
                    <div class="icon-sprite dropdown"></div>
                </div>
            </div>

            <button type="button" class="btn submit-btn" @click="book">
                Book Appointment
            </button>

            <button type="submit" class="btn confirm-btn d-none" @click.prevent="confirm">
                Confirm Appointment
            </button>

        </form>

    </div>
</div>
</template>

<script>
import { Appointments } from '@/services/Service.js';
import Datepicker from 'vuejs-datepicker';
import $ from 'jquery';

const timeStramp = [{time: '9 AM', disabled: false},{time: '10 AM', disabled: false},{time: '11 AM', disabled: false},{time: '12 PM', disabled: false}];
for(let i = 1; i<9; i++ ) timeStramp.push({
    time: i + ' PM',
    disabled: false
});

export default {
    components: {
        Datepicker
    },
    data() {
        return {
            appDate: '',
            timeStramp,
            cName: '',
            cEmail: '',
            cMobileNumber: '',
            disabledDates: {
                to: new Date(),
                days: [0]
            },
            appTime: '',
            appType: ''
        }
    },
    methods: {
        handleInput(e)
        {
            if( e.target.value === '' )
                this.addErrorMessage( e.target.id );
            else
                this.removeErrorMessage( e.target.id );
        },
        validateFields({target: el})
        {
            const { name, len } = el.dataset;
            if(el.value === '')
                this.addErrorMessage( el.id );
            else if( el.value.length < len )
                this.addErrorMessage( el.id, `${name} must have atleast ${len} digits.`);
        },
        validateEmail({target: el})
        {
            if(el.value === '')
                return this.addErrorMessage( el.id );

            var atposition = el.value.indexOf("@");
            var dotposition = el.value.lastIndexOf(".");

            if( atposition<1 || dotposition<atposition+2 || dotposition+2>=el.value.length)
                this.addErrorMessage( el.id, `Invalid Email.`);
        },
        addErrorMessage( id, msg )
        {
            $(`.${id}-error-msg`).remove();
            var contents;
            if(msg)
                contents = `<p class="error-msg ${id}-error-msg">${msg}</p>`;
            else
                contents = `<p class="error-msg ${id}-error-msg">${$(`#${id}`).data('name')} can't be empty </p>`;
            $(`#${id}`).parent().addClass('error-parent').append(contents);
        },
        removeErrorMessage(id)
        {
            $(`.${id}-error-msg`).remove();
            $(`#${id}`).parent().removeClass('error-parent');
        },
        book() {
            if( this.appDate === '' )
                this.addErrorMessage('proxy-date');
            if( this.appTime === '' )
                this.addErrorMessage('app-time');

            if( $('#book-app .error-msg').length ) return;

            $('.submit-btn').addClass('d-none');
            $('.customer-row-1').removeClass('d-none');
            $('.customer-row-2').removeClass('d-none');
            $('.confirm-btn').removeClass('d-none');
        },
        async confirm() {
            if( this.appDate === '' )
                this.addErrorMessage('proxy-date');
            if( this.appTime === '' )
                this.addErrorMessage('app-time');

            if( this.cEmail === '' )
                this.addErrorMessage('c-email');

            if( this.cName === '' )
                this.addErrorMessage('c-name');
            else if( this.cName.length < 3 )
                this.addErrorMessage('c-name', 'name must have atleast 3 chars.' );

            if( this.cMobileNumber === '' )
                this.addErrorMessage('c-mobile-number');
            else if( this.cMobileNumber.length !== 10 )
                this.addErrorMessage('c-mobile-number', 'Mobile number must have atleast 10 digits.');

            if( this.appType === '' )
                this.addErrorMessage('app-type');

            if( $('#book-app .error-msg').length ) return;
            $('.confirm-btn').blur();
            $('.confirm-btn').html(`<div class="spinner-border" role="status"></div>`);
            try {
                const response = await Appointments.add({
                    date: this.appDate,
                    time: this.appTime,
                    type: this.appType,
                    customerName: this.cName,
                    customerEmail: this.cEmail,
                    mobileNumber: this.cMobileNumber
                });
                if( response.status === 200 )
                {
                    const notification = {
                        type: 'success',
                        message: 'Booking successful.'
                    };
                    this.$store.dispatch('addNotification', notification);
                    $('.app-time-label').removeClass('d-none');
                    $('.confirm-btn').html(`Book Appointment`);
                    $('.submit-btn').removeClass('d-none');
                    $('.customer-row-1').addClass('d-none');
                    $('.customer-row-2').addClass('d-none');
                    $('.confirm-btn').addClass('d-none');
                    this.appDate = '';
                    this.appTime = '';
                    this.cName = '';
                    this.appType = '';
                    this.cEmail = '';
                    this.cMobileNumber = '';
                    $('.time-outer').addClass('d-none');
                }
            } catch (error)
            {
                const { message } = error.response.data;
                const notification = { type: 'error', message }
                this.$store.dispatch('addNotification', notification);
                $('.confirm-btn').html(`Book Appointment`);
            }
        }
    },
    watch: {
        async appDate(newValue) {
            if( newValue === '' ) return;
            this.removeErrorMessage( 'proxy-date' );
            this.timeStramp.forEach( el => el.disabled = false );
            $('#app-time').focus();
            $('.submit-btn').blur();
            $('.submit-btn').html(`<div class="spinner-border" role="status"></div>`);
            try {
                const response = await Appointments.get({ date: newValue });
                if( response.status === 200 )
                {
                    response.data.times.forEach( t => {
                        var ind = this.timeStramp.findIndex( el => el.time === t );
                        this.timeStramp[ind].disabled = true;
                    });
                    $('.submit-btn').html(`Book Appointment`);
                    $('.time-outer').removeClass('d-none');
                }
            } catch (error)
            {
                console.log(error.response.data);
                const notification = { type: 'error', message: error.response.data.message };

                this.$store.dispatch('addNotification', notification);
            }
        },
        appTime(newValue) {
            if( newValue === '' ) return;

            $('.app-time-label').addClass('d-none');
            this.removeErrorMessage( 'app-time' );
        },
        appType(newValue) {
            newValue && this.removeErrorMessage( 'app-type' );
        }
    },
}
</script>


<style>

.app-dp-input
{
    cursor: context-menu;
    outline: 0;
    background-color: rgb(0, 0, 0,0.7);
    color: white;
    border: 2px solid #E20613;
    padding-left: 18px;
    height: 50px; width: 300px;
}

.app-dp-input::placeholder { color: white; }

.app-dp-cal
{
    background-color: black;
    color: black;
}

.app-dp-wrapper
{
    color: white;
}

@media (max-width: 475px)
{
    .app-dp-input
    { width: 100% !important; }
}

</style>

<style scoped>

#book-app
{
    padding: 100px 0;
    background-image: url('../../assets/Background Images/repair_background.jpg');
    background-size: cover;
    color: #ffffff;
    position: relative;
    z-index: 1;
}

#book-app::before
{
    content: '';
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    background-color: rgb(0, 0, 0,0.7);
    z-index: -1;
}

p
{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 1.75;
    margin-bottom: 40px;
}

form { flex-direction: column; }

form>* { margin-bottom: 24px; }

.date
{
    background-size: 596.7px 416.7px;
    background-position: -558.3px -15px;
	height: 20px; width: 20px;
    position: absolute; z-index: 2;
    top: 16px; right: 15px;
    pointer-events: none;
}

.form-input
{
    height: 50px; width: 300px;
    border: 2px solid #E20613;
    padding-left: 18px;
    background-color: rgb(0, 0, 0,0.7);
    box-shadow: 3px 3px 4px rgb(255, 255, 255,0.15);
    outline: 0;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

.form-input::placeholder { color: #fff; }

.form-input:focus { border: 2px solid white; }

.form-element
{
    position: relative;
    width: 300px;
}

.time-outer, .app-type-outer, .c-email-outer
{ margin-left: 20px; }

.submit-btn, .confirm-btn
{
    height: 50px; width: 200px;
    border-radius: 3px;
    box-shadow: 0px 0px 6px rgb(255, 255, 255,0.2);
    transition: all 0.1s ease;
    border: 2px solid transparent;
    background-color: #E20613;
    font-weight: 600;
    font-size: 17px;
    color: #ffffff;
}

.submit-btn:hover, .confirm-btn:hover
{
    background-color: rgb(0, 0, 0,0.25);
    border: 2px solid #E20613;
}

.dropdown
{
    background-size: 83.9px 58.6px;
    background-position: -54.6px -12.9px;
	height: 7.5px; width: 15px;
    position: absolute;
    top: 22px; right: 15px;
}

.app-time-label
{
    position: absolute;
    left: 18px;
    top: 18px;
}


@media (max-width: 1023px)
{
    #book-app { background-position-x: -300px; }
}


@media (max-width: 767px)
{
    .form-row { flex-direction: column; align-items: center; width: 100%; }

    .time-outer,.app-type-outer, .c-email-outer { margin: 20px 0 0; }

    .submit-btn, .form-input
    { margin-left: auto; margin-right: auto; }

    .submit-btn { width: 275px; }

    p { text-align: justify; }

    .submit-btn, .confirm-btn { margin: 0 auto; width: 300px; }

    #book-app { background-position-x: -450px; }
}

@media (max-width: 475px)
{
    .date-outer, .time-outer, .form-input, .submit-btn , .confirm-btn, .form-element
    { width: 100% !important; }

    #book-app { background-position-x: -600px; }
}

</style>