import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/user/Home';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/getquote',
		name: 'getquote',
		component: () =>
			import(/* webpackChunkName: "Get Quote" */ '../views/user/GetQuote')
	},
	{
		path: '/bookappointment',
		name: 'bookappointment',
		component: () =>
			import(
				/* webpackChunkName: "BookAppointment" */ '../views/user/BookAppointment'
			)
	},
	{
		path: '/trackorder',
		name: 'trackorder',
		component: () =>
			import(
				/* webpackChunkName: "Track Order" */ '../views/user/TrackOrder'
			)
	},
	{
		path: '/locateus',
		name: 'locateus',
		component: () =>
			import(/* webpackChunkName: "LocateUs" */ '../views/user/LocateUs')
	},
	{
		path: '/admin/login',
		name: 'login',
		component: () =>
			import(/* webpackChunkName: "Admin" */ '../views/admin/Login')
	},
	{
		path: '/admin/signup',
		name: 'signup',
		component: () =>
			import(/* webpackChunkName: "Signup" */ '../views/admin/Signup'),
		meta: { requiresAuth: true }
	},
	{
		path: '/admin/orders',
		name: 'orders',
		component: () =>
			import(/* webpackChunkName: "orders" */ '../views/admin/Orders'),
		meta: { requiresAuth: true }
	},
	{
		path: '/admin/appointments',
		name: 'appointments',
		component: () =>
			import(
				/* webpackChunkName: "appointments" */ '../views/admin/Appointments'
			),
		meta: { requiresAuth: true }
	},
	{
		path: '/admin/feedbacks',
		name: 'feedbacks',
		component: () =>
			import(
				/* webpackChunkName: "feedbacks" */ '../views/admin/Feedbacks'
			),
		meta: { requiresAuth: true }
	},
	{
		path: '/admin/quote',
		name: 'quote',
		component: () =>
			import(/* webpackChunkName: "quotes" */ '../views/admin/Quotes'),
		meta: { requiresAuth: true }
	},
	{
		path: '/admin/profile',
		name: 'profile',
		component: () =>
			import(/* webpackChunkName: "quotes" */ '../views/admin/AdminProfile'),
		meta: { requiresAuth: true }
	},
	{
		path: '/admin/orders/:id',
		name: 'orderPage',
		component: () =>
			import(/* webpackChunkName: "eachorderpage" */ '../views/admin/OrderPage'),
		meta: { requiresAuth: true }
	},
	{
		path: '/admin/orders/edit-order/:id',
		name: 'orderEdit',
		component: () =>
			import(/* webpackChunkName: "eachorderedit" */ '../views/admin/OrderEdit'),
		meta: { requiresAuth: true }
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	const loggedIn = localStorage.getItem('AdminUser');
	if (to.matched.some((re) => re.meta.requiresAuth) && !loggedIn)
		next('/admin/login');
	next();
});

export default router;
