<template>
    <div class="notification-bar flex align-center" :class="notificationTypeClass">
        <div class="admin-icons"></div>
        <p>
            {{ notification.message }}
        </p>
        <span class="close-icon" @click="removeNotification(notification)"></span>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import $ from 'jquery';

export default {
    props: {
        notification: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            timeout: null
        }
    },
    mounted () {
        this.timeout = setTimeout( () => {
            $('.notification-bar').addClass('closing');
            setTimeout( () => this.removeNotification(this.notification), 400 );
        }, 4000 );
    },
    computed: {
        notificationTypeClass() {
            return this.notification.type + "-notification"
        }
    },
    methods: {
        ...mapActions(['removeNotification'])
    },
    beforeDestroy() {
        clearTimeout(this.timeout);
    }
}
</script>

<style scoped>

.notification-bar
{
    margin: 15px 0;
    padding: 15px 24px;
    box-shadow: 0px 6px 12px rgb(0, 0, 0,0.1);
    border-radius: 6px;
    animation: open-notif 0.5s ease;
    cursor: context-menu;
    max-width: 600px;
    position: relative;
    pointer-events: all;
}

@keyframes open-notif
{
    from { transform: translateX(100%); }

    to { transform: translateX(0px); }
}

@keyframes close-notif
{
    from { transform: translateX(0px); }

    to { transform: translateX(100%); }
}

.notification-bar.closing { animation: close-notif 0.5s ease !important; }

p
{
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    padding-top: 3px;
    min-width: 300px;
    max-width: calc(100% - 100px);
    line-height: 1.5;
}

.error-notification { background-color: #E20613; }

.success-notification { background-color: #00af91; }

.error-notification .admin-icons
{
    background-size: 269.4px 227.4px;
    width: 30px; height: 30px;
    background-position: -90px -191.4px;
}

.success-notification .admin-icons
{
    background-size: 269.4px 227.4px;
    width: 30px; height: 30px;
    background-position: -6px -191.4px;
}

.admin-icons { margin-right: 12px; }

.close-icon
{
    cursor: pointer;
    width: 20px; height: 20px;
    position: absolute;
    top: 15px; right: 25px;
}

.close-icon::before, .close-icon::after
{
    content: '';
    position: absolute;
    width: 3px; height: 20px;
    top: 0; left: 50%;
    background-color: white;
}

.close-icon::before { transform: translateX(-50%) rotate(45deg); }

.close-icon::after { transform: translateX(-50%) rotate(-45deg); }

.close-icon:hover::before, .close-icon:hover::after
{ background-color: #000; }

@media (max-width: 525px)
{
    .notification-bar
    {
        flex-direction: column;
        width: 100%;
        text-align: center;
    }

    .admin-icons { margin-right: 0; margin-bottom: 12px; }

    .notification-bar p
    {
        min-width: 100%;
    }
}

</style>