<template>
    <div id="home" >
        <NavBar />
        <Banner />
        <AboutUs />
        <QuoteComponent />
        <Testimonials />
        <AppointmentComponent />
        <LocateComponent />
        <PrivacyPolicy />
        <GetInTouch />
        <TheFooter />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import TheFooter from '@/components/TheFooter.vue';
import Banner from '@/components/user/Banner.vue';
import AboutUs from '@/components/user/AboutUs.vue';
import QuoteComponent from '@/components/user/QuoteComponent.vue';
import Testimonials from '@/components/user/Testimonials.vue';
import AppointmentComponent from '@/components/user/AppointmentComponent.vue';
import PrivacyPolicy from '@/components/user/PrivacyPolicy.vue';
import LocateComponent from '@/components/user/LocateComponent.vue';
import GetInTouch from '@/components/user/GetInTouch.vue';

export default {
	components: {
        NavBar,
		TheFooter,
        Banner,
        AboutUs,
        QuoteComponent,
        Testimonials,
        AppointmentComponent,
        LocateComponent,
        PrivacyPolicy,
        GetInTouch
	}
}
</script>