<template>
<div id="privacy-pol">
    <div class="container flex">
        <div class="icon-sprite pp-icon"></div>
        <div class="pp-text">
            <h2 class="sub-headings">Privacy <span>Policy</span> </h2>
            <p>
                Our company pays special attention to privacy of your data. No private information from your mobile will be seen or opened by us. We respect our customers privacy.
            </p>
        </div>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
#privacy-pol
{
    padding: 100px 0;
    background-image: url('../../assets/Background Images/background_1.jpg');
    background-size: cover;
    position: relative;
    z-index: 1;
    color: #fff;
}

.container
{
    justify-content: space-around;
    align-items: center;
}

.pp-icon
{
    background-size: 688.5px 480.8px;
    background-position:  -9.6px -211.5px;
	height: 200px; width: 153.8px;
}

.pp-text { width: calc(100% - 400px); }

p
{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 1.75;
}

@media (max-width: 767px)
{
    .pp-icon { display: none; }

    .pp-text { width: 100%; text-align: justify; }
}

</style>