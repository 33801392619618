<template>
    <div id="app" class="flex">
        <router-view />
        <NotificationContainer />
    </div>
</template>

<script>
import NotificationContainer from '@/components/Notification/NotificationContainer.vue';

export default
{
	components: {
        NotificationContainer
	}
}
</script>

<style>

/* General Stylings */

*, *::before, *::after
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Josefin Sans', sans-serif;
}

html { overflow-x: hidden; scroll-behavior: smooth; height: 100%; scrollbar-color: #E20613; }

body { overflow-x: hidden; height: 100%; scrollbar-color: #E20613; }

h1, h2, h3, h4, h5, h6 { font-weight: normal; }

ul { list-style-type: none; }

a { text-decoration: none; }

i { font-style: normal; }

img { display: block; }

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
{ -webkit-appearance: none; margin: 0; }

input[type=number] { -moz-appearance: textfield; }

select { appearance: none; }

:last-child { margin-bottom: 0; }

body::-webkit-scrollbar { width: 15px; }

body::-webkit-scrollbar-track { background: black; }

body::-webkit-scrollbar-thumb { background: #E20613; }

@media only screen and (max-width: 767px)
{
    body::-webkit-scrollbar { width: 0px; }
}

/* General Stylings */

html.no-scroll { overflow: hidden; }

html.no-scroll body { overflow: hidden; }

.container
{
    max-width: 1330px;
    margin: 0 auto;
    padding: 0 15px;
}

.flex
{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}

.center
{
    align-items: center;
    justify-content: center;
}

.align-center { align-items: center; }

.justify-btw { justify-content: space-between; }

.btn
{
    border: 0;
    outline: 0;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.fancy-btn::after
{
    content: "";
    position: absolute;
    top: 0; left: 0;
    z-index: -1;
    width: 100%; height: 100%;
    background-color: rgb(0, 0, 0,0.5);
    border-radius: inherit;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-out;
}

.fancy-btn:hover::after, .fancy-btn:focus::after { transform: scaleX(1); }

.d-none { display: none !important; }

.hamburger { display: none; }

.icon-sprite
{
    background-image: url('./assets/icon sprite.png');
    background-size: 1432px 1000px;
    background-repeat: no-repeat;
}

.admin-icons
{
    background-image: url('./assets/admin-icons.png');
    background-size: 449px 379px;
    background-repeat: no-repeat;
}

.sub-headings
{
    display: inline-block;
    text-transform: uppercase;
    position: relative;
    font-weight: 800;
    font-size: 30px;
    line-height: 1.5;
    margin-bottom: 35px;
}

.sub-headings::after
{
    position: absolute;
    content: '';
    height: 4px; width: 75px;
    background-color: #E20613;
    bottom: -6px;
    left: 0;
}

.sub-headings span { color: #E20613; }

h2.sub-headings.centered
{
    display: block;
    text-align: center;
    margin-bottom: 50px;
}

h2.sub-headings.centered::after
{
    left: 50%;
    transform: translateX(-50%);
}

.error-parent
{
    position: relative;
    margin-bottom: 20px;
}

p.error-msg, p.info-msg
{
    font-weight: 600;
    font-size: 15px;
    text-transform: capitalize;
    margin-top: 15px;
}

p.error-msg { color: #E20613; }

p.info-msg { color: #00AF91; }

.spinner-border
{
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.pseudo-loader
{
    display: block;
    height: 40px; width: 200px;
    background-color: rgb(0, 0, 0,0.5);
    border-radius: 25px;
    position: relative;
    overflow: hidden;
}

.pseudo-loader::after
{
    content: '';
    position: absolute;
    height: 100%; width: 30%;
    background-color: rgb(255, 255, 255,0.25);
    top: 0; left: 0;
    animation: load 0.4s linear infinite;
    transform: skewX(30deg);
}

.pseudo-loader.small { width: 40px; height: 40px; }

.pseudo-loader.round { border-radius: 50%; }

.ml-auto { margin-left: auto !important; }

.ml-3 { margin-left: 15px; }

@keyframes load
{
    from { left: 0; }

    to { left: 100%; }
}

@media (max-width: 767px)
{
    h2.sub-headings
    {
        display: block;
        text-align: center;
        margin-bottom: 50px;
    }

    h2.sub-headings::after
    {
        left: 50%;
        transform: translateX(-50%);
    }

    ::-webkit-scrollbar { width: 0px; }
}

/* Local Stylings */

#app
{
    width: 100%; min-height: 100%;
    flex-direction: column;
    background-image: url('./assets/Goemetirc Textures.png');
}

@media (max-width: 425px)
{
    .sub-headings { font-size: 25px; }
}

</style>