import Vue from 'vue';
import Vuex from 'vuex';
import { UpdateApiClient, config, SMS } from '../services/Service';

Vue.use(Vuex);
var nextId = 1;

export default new Vuex.Store({
	state: {
		notifications: [],
		orders: [],
		ordersStored: false,
		appointments: [],
		appointmentsStored: false,
		feedbacks: [],
		feedbacksStored: false,
		user: null,
		smsBalance: null
	},
	mutations: {
		PUSH_NOTIFICATION(state, notification) {
			state.notifications.push({
				...notification,
				id: nextId++
			});
		},
		DELETE_NOTIFICATION(state, notificationToRemove) {
			state.notifications = state.notifications.filter(
				(notification) => notification.id !== notificationToRemove.id
			);
		},
		SET_USER_DATA(state, userData) {
			state.user = userData;
			localStorage.setItem('AdminUser', JSON.stringify(userData));
			config.headers['auth-token'] = userData.authToken;
			UpdateApiClient();
		},
		CLEAR_USER_DATA(state) {
			state.user = null;
			localStorage.removeItem('AdminUser');
			delete config.headers['auth-token'];
			UpdateApiClient();
		},
		STORE_ORDERS(state, orders) {
			state.orders = orders;
			state.ordersStored = true;
		},
		STORE_APPOINTMENTS(state, appointments) {
			state.appointments = appointments;
			state.appointmentsStored = true;
		},
		STORE_FEEDBACKS(state, feedbacks) {
			state.feedbacks = feedbacks;
			state.feedbacksStored = true;
		},
		PUSH_ORDER(state, order) {
			state.orders.push( order );
		},
		SET_SMS_BALANCE(state, balance) {
			state.smsBalance = balance;
		}
	},
	actions: {
		addNotification({ commit }, notification) {
			commit('PUSH_NOTIFICATION', notification);
		},
		removeNotification({ commit }, notificationToRemove) {
			commit('DELETE_NOTIFICATION', notificationToRemove);
		},
		loginUser({ commit }, userData) {
			commit('SET_USER_DATA', userData);
		},
		logoutUser({ commit }) {
			commit('CLEAR_USER_DATA');
		},
		storeOrders({commit}, payload) {
			commit('STORE_ORDERS', payload);
		},
		storeAppointments({commit}, payload) {
			commit('STORE_APPOINTMENTS', payload);
		},
		storeFeedbacks( {commit}, payload) {
			commit('STORE_FEEDBACKS', payload);
		},
		pushOrder({commit}, payload) {
			commit('PUSH_ORDER', payload);
		},
		async getSMSBalance({commit}) {
			const resp = await SMS.getBalance();
			commit('SET_SMS_BALANCE', resp.data.wallet );
			return true;
		},
	},
	getters: {
		loggedIn: state => !!state.user,
		allOrders: state => state.orders,
		activeOrders: state => state.orders.filter( order => !order.fixed && !order.delivered ),
		fixedOrders: state => state.orders.filter( order => order.fixed && !order.delivered ),
		deliveredOrders: state => state.orders.filter( order => order.fixed && order.delivered ),
		getOrderByID: state => id => state.orders.find( order => order.orderID === id ),
		allAppointments: state => state.appointments,
		offlineApps: state => state.appointments.filter( app => app.type === 'offline' ),
		visitShopApps: state => state.appointments.filter( app => app.type === 'visitshop' ),
		getAppointmentByID: state => id => state.appointments.find( app => app.appointmentID === id ),
		allFeedbacks: state => state.feedbacks,
		displayedFeedbacks: state => state.feedbacks.filter( fb => fb.isDisplayed )
	}
});
