import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vue2TouchEvents from 'vue2-touch-events'

Vue.config.productionTip = false
Vue.use(Vue2TouchEvents)

new Vue({
  router,
  store,
  created () {
      const userString = localStorage.getItem('AdminUser');
      if( userString )
      {
        const userData = JSON.parse(userString);
        this.$store.commit('SET_USER_DATA',userData);
      }
  },
  render: h => h(App)
}).$mount('#app')
