<template>
  <nav class="flex align-center justify-btw">
    <router-link :to="{ name: 'home' }" class="nav-brand">
      <img src="../assets/logo-2.jpeg" alt="Mobile Solutions Logo" />
    </router-link>

    <ul
      class="nav-links flex"
      :class="{ opened: menuOpened, 'menu-close': isMenuClosing }"
    >
      <li>
        <router-link
          :to="{ name: 'getquote' }"
          class="flex align-center"
          @click.native="handleLinkClick"
        >
          Get Quotation
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'bookappointment' }"
          class="flex align-center"
          @click.native="handleLinkClick"
        >
          Book Appointment
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'trackorder' }"
          class="flex align-center"
          @click.native="handleLinkClick"
        >
          Track Order
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'locateus' }"
          class="flex align-center"
          @click.native="handleLinkClick"
        >
          Locate Us
        </router-link>
      </li>
      <li class="admin-link">
        <router-link
          :to="{ name: 'orders' }"
          class="flex align-center"
          @click.native="handleLinkClick"
        >
          Admin
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'orders' }"
          class="flex center admin-box"
          @click.native="handleLinkClick"
        >
          <div class="icon-sprite user-icon"></div>
        </router-link>
      </li>
    </ul>

    <button
      class="hamburger hamburger--spin btn"
      :class="{ 'is-active': menuOpened }"
      @click="handleHamburgerClick"
    >
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      menuOpened: false,
      isMenuClosing: false,
    };
  },
  methods: {
    handleHamburgerClick() {
      if (this.menuOpened) {
        this.isMenuClosing = true;
        document.querySelector('html').classList.remove('no-scroll');
        setTimeout(() => {
          this.menuOpened = false;
          this.isMenuClosing = false;
        }, 500);
      } else {
        this.menuOpened = true;
        document.querySelector('html').classList.add('no-scroll');
      }
    },
    handleLinkClick(e) {
      if (this.menuOpened) {
        // close menu
        this.isMenuClosing = true;
        document.querySelector('html').classList.remove('no-scroll');
        setTimeout(() => {
          this.menuOpened = false;
          this.isMenuClosing = false;
        }, 500);
      }
      e.preventDefault();
    },
  },
};
</script>

<style scoped>
nav {
  margin: 0 auto;
  height: 100px;
  width: 100%;
  box-shadow: 0px 0px 12px rgb(0, 0, 0, 0.15);
  background-color: white;
  position: relative;
  z-index: 2;
}

.nav-brand img {
  height: 80px;
  margin-left: 15px;
}

.nav-links a {
  height: 100px;
  padding: 5px 15px 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.nav-links a:hover,
.nav-links a.router-link-exact-active {
  color: #e20613;
}

.nav-links a.admin-box {
  margin-left: 24px;
  padding: 0;
  width: 100px;
  background-color: #000000;
}

.user-icon {
  background-size: 130.5px 91.1px;
  background-position: -1.8px -1.8px;
  height: 35px;
  width: 35px;
}

.nav-links a.admin-box:hover .user-icon {
  background-position: -45px -1.8px;
}

.admin-link {
  display: none;
}

@media (max-width: 1024px) {
  .hamburger {
    height: 100px;
    width: 100px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.15s linear, filter 0.15s linear;
    background-color: #000;
  }

  .hamburger-box {
    width: 35px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
    background-color: #ffffff;
    color: #ffffff;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 30px;
    height: 4px;
    border-radius: 4px;
    position: absolute;
    transition: transform 0.15s ease;
  }

  .hamburger-inner::before,
  .hamburger-inner::after {
    content: '';
    width: 40px;
    display: block;
    background-color: currentColor;
  }

  .hamburger.is-active .hamburger-inner {
    width: 40px;
  }

  .hamburger-inner::before {
    top: -10px;
  }

  .hamburger-inner::after {
    bottom: -10px;
  }

  .hamburger--spin .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  }

  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in,
      transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .hamburger--spin.is-active .hamburger-inner {
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
  }

  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out,
      transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .nav-links,
  .nav-links li:last-child {
    display: none;
  }

  .nav-links.opened {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: calc(100vh - 100px);
    width: 100vw;
    background-color: #ffffff;
    position: absolute;
    top: 100px;
    left: 0;
    z-index: 3;
    overflow: hidden;
    border-bottom: 3px solid rgb(0, 0, 0, 0.5);
    box-shadow: inset 0px 0px 12px rgb(0, 0, 0, 0.15);
    animation: menu-open-anm 0.5s ease;
  }

  .nav-links.opened a {
    width: 100vw;
    height: 60px;
    font-size: 14px;
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 12px 12px;
    border-bottom: 1.5px solid rgb(0, 0, 0, 0.8);
  }

  .admin-link {
    display: block;
  }

  .nav-links a.router-link-exact-active {
    color: #000;
  }

  .nav-links.opened .admin-link a {
    background-color: #000;
    color: #ffffff;
  }

  .nav-links.opened.menu-close {
    animation: menu-close-anm 0.5s ease;
  }

  @keyframes menu-open-anm {
    from {
      height: 0px;
    }

    to {
      height: calc(100vh - 100px);
    }
  }

  @keyframes menu-close-anm {
    0% {
      height: calc(100vh - 100px);
    }

    100% {
      height: 0px;
    }
  }
}

@media screen and (max-width: 600px) {
  .nav-brand img {
    height: auto;
    width: 200px;
    margin-left: 0;
  }
}
</style>
